import api from '../api';
import IAut2FatCod from '../interfaces/aut2FatCod'


const getCodigoAut2Fat= async function (
    request: IAut2FatCod 
) {
    // debugger;
    return await api.post('/adf', request);
}


const autAplicarCodigo  = async function (codigo: string) {

    return await api.get('/adf/aplicar', {
        params: {Codigo: codigo}
      });
}


const aut2FatService = {
    autAplicarCodigo,
    getCodigoAut2Fat
}

export default aut2FatService;
 