import React, { useEffect } from 'react';
import { Route, Redirect, useLocation} from 'react-router-dom';
import { useUserContext } from '../authentication/userContextProvider'
import IPage from '../interfaces/page';

interface RouteWrapperProps {
    component: React.FC<IPage>;
    isPrivate?: boolean;
    isAuthentication?: boolean;
    [rest: string]: any;
}

// Utilizado para gerenciar as rotas e redirecionamentos
const RouteWrapper: React.FC<RouteWrapperProps> = ({ component: Component, isPrivate=false, isAuthentication=false, isNBA=false, ...rest }) => {
	const { user, logout } = useUserContext();
  const { pathname } = useLocation();
  const isAuthenticated = user?.isAuthenticated || !!localStorage.getItem("tokenJwt");
  let ispCode;
  
  useEffect(() => {
    if(user) {
      if(isAuthenticated && (pathname.split("/")[1] !== user?.isp?.codigoUrl)) {
        logout();
      }
    }
  }, [user, pathname, isAuthenticated, logout])

  if(isNBA){
    ispCode = "nba-acesso"
  }else{
    ispCode = rest?.computedMatch?.params?.isp_code;
  }
  
  //Caso o usuario NBA tente acessar outra rota, redireciona para login
  if(localStorage.getItem("isNBAAcess") == '1'){
    if(!isNBA){
      return <Redirect to={{pathname: `/${ispCode}`}} />;
    }
  }
	// // Caso o usuário não esteja logado e a rota é privada, redireciona para o login
	if(!isAuthenticated && isPrivate) {
		return <Redirect to={{pathname: `/${pathname.split("/")[1]}`}} />;
	}

  if (isAuthenticated && !isPrivate) {
    return (
      <Redirect to={{ pathname: `/${pathname.split("/")[1]}/servicos` }} />
    );
  }
  
  // Caso tudo esteja certo, redireciona para a página desejada
  return <Route {...rest} component={Component}/>;
}
export default RouteWrapper;