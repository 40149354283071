import api from '../api';
import IAuthenticationData from '../interfaces/AuthenticationData';

const postLogin = function (username:string, password:string, codigoIsp:string, data:IAuthenticationData, codigo?:string, acessoNBA = false) {
    var auths = username+':'+password;
    if(acessoNBA){
        auths = auths + ':' + codigo
    }
    var result = api.post(`/authentication/${codigoIsp}`, {data: data}, {
        headers:{
           Authorization: 'Basic '+btoa(auths),
        }
   });
    return result
}

const authenticationService = {
    postLogin
}

export default authenticationService;
