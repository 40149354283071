import api from '../api';
import IIsp from '../interfaces/isp';

const listIsps = async function () {
    return await api.get('/isps');
}

const getIspByCodigoUrl = async function (codigoUrl:string) {
    // TODO: IMPLEMENTAR REQUEST PARA A API
    return await api.get(`/isp/${codigoUrl}`);
}
const getIspsByCodigoUrl = async function (codigoUrl:string): Promise<IIsp[]>  {
    // TODO: IMPLEMENTAR REQUEST PARA A API
    return await api.get(`/isp/${codigoUrl}`);
}

const getIspsByCodigoUrlRegionalizacao = async function (codigoUrl:string) {
    // TODO: IMPLEMENTAR REQUEST PARA A API
    return await api.get(`/isp/${codigoUrl}/regionalizacao`);
}
const ispService = {
    listIsps,
    getIspByCodigoUrl,
    getIspsByCodigoUrl,
    getIspsByCodigoUrlRegionalizacao
}

export default ispService;
