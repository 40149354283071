const 
Message = {
    acessar: "Acessar",
    loginTelefone: "Login por telefone",
    loginUsuario: "Login por usuário",
    defaultError: "Desculpe, ocorreu um erro inesperado. Tente novamente mais tarde.",
    defaultSuccess: "Operação realizada com sucesso.",
    maximoQuantidadePacotes: "Você já atingiu a quantidade máxima de produtos selecionados para essa categoria",
    excedeuQuantidadePermitidaGeneric: "Limite de aplicativos excedido.",
    excedeuQuantidadePermitidaStandard: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Standard para salvar sua seleção.",
    excedeuQuantidadePermitidaPremium: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Premium para salvar sua seleção.",
    excedeuQuantidadePermitidaNivel004: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Advanced para salvar sua seleção.",
    excedeuQuantidadePermitidaNivel005: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Premium Anúncio para salvar sua seleção.",
    excedeuQuantidadePermitidaNivel006: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Premium Super para salvar sua seleção.",
    excedeuQuantidadePermitidaNivel007: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Premium Super Extra para salvar sua seleção.",
    salvar: "Salvar", 
    serverTimeout: "Desculpe, não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.",
    senha: "Senha",
    solicitacaoLogin: "Faça login para continuar.",
    usuario: "Usuario",
    telefone: "",
    codigo: "Código",
    sms: "SMS",
    whatsapp: "WhatsApp",
    usuarioOffline: 'Por favor, verifique sua conexão com a internet antes de continuar.',
}

export default Message;