import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { createContext, useCallback, useState } from 'react';
import Message from '../config/message';
import INotificationContext from '../interfaces/alertContext';
import useOnlineStatus from '../hooks/useOnlineStatus';
import IAlertMessage from '../interfaces/alert';

const NotificationContext = createContext<INotificationContext | undefined>(undefined);

const NotificationContextProvider: React.FunctionComponent<{}> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alertApi, setAlertApi] = useState<IAlertMessage>();
    const [alertApp, setAlertApp] = useState<IAlertMessage>();

    const isOnline = useOnlineStatus();

    const handleCloseApp = useCallback(() => {
        setAlertApp((prevState:any) => {
            return {
                ...prevState,
                isOpen: false
            }
        });
    }, []);

    const handleCloseApi = useCallback(() => {
        setAlertApi((prevState:any) => {
            return {
                ...prevState,
                isOpen: false
            }
        });
    }, []);

    const showAlertApi = function (message:string, severity:"error"|"info"|"warning"|"success", timeout?:number) {
        setAlertApi({message, severity, timeout, isOpen: true});
    }

    const showAlertApp = function (message:string, severity:"error"|"info"|"warning"|"success", timeout?:number) {
        setAlertApp({ message, severity, timeout, isOpen: true });
    }

    const showLoading = function () {
        setIsLoading(true);
    }

    const hideLoading = function () {
        setIsLoading(false);
    }

    const messageContext = {
        showAlertApi,
        showAlertApp,
        showLoading,
        hideLoading
    }


    return (
        <NotificationContext.Provider value={messageContext}>
            {props.children}
            <Snackbar
                open={alertApi?.isOpen}
                autoHideDuration={alertApi?.timeout}
                onClose={alertApi?.timeout ? handleCloseApi : undefined}
                anchorOrigin={{horizontal: "center", vertical: "bottom"}}
            >
                <Alert severity={alertApi?.severity} onClose={!alertApi?.timeout ? handleCloseApi : undefined} variant="filled" style={{width: '100%', maxWidth: 600}}>
                    <div style={{whiteSpace: "pre-wrap"}}>
                        {alertApi?.message}
                    </div>
                </Alert>
            </Snackbar>

            <Backdrop open={isLoading} style={{zIndex: 999999, color: "#fff"}}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={!isOnline}
                anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                autoHideDuration={5000}
            >
                <Alert severity="warning" variant="filled" style={{width: '100%', maxWidth: 600}}>
                    {Message.usuarioOffline}
                </Alert>
            </Snackbar>

            <Snackbar
                open={alertApp?.isOpen}
                autoHideDuration={alertApp?.timeout}
                onClose={alertApp?.timeout ? handleCloseApp : undefined}
                anchorOrigin={{horizontal: "center", vertical: "top"}}
            >
                <Alert severity={alertApp?.severity} onClose={!alertApp?.timeout ? handleCloseApp : undefined} variant="filled" style={{width: '100%', maxWidth: 600}}>
                    <div style={{whiteSpace: "pre-wrap"}}>
                        {alertApp?.message}
                    </div>
                </Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
}

export function useMessage(): INotificationContext {
    const context = React.useContext(NotificationContext)
    if (context === undefined) {
        throw new Error('useMessage must be used within a NotificationContextProvider')
    }
    return context
}

export default NotificationContextProvider