import api from '../api';

const listaEquipes = async function () {
  return await api.get('/nba/equipes');
};

const atualizaEquipeEscolhida = async function (id: string) {
  const data = {
    idNbaEquipe: id,
  };
  return await api.post('/nba/updateEquipe', data);
};

const nbaService = {
  listaEquipes,
  atualizaEquipeEscolhida,
};

export default nbaService;
