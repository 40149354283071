import { useEffect, useState } from "react";
import { usePickStyle } from "./usePickStyle";
import { useUserContext } from "../authentication/userContextProvider";

interface IAvatarState {
  internal: boolean;
  external: boolean;
}

interface IUrlState {
  internal: string | undefined;
  external: string | undefined;
}

function extractUrlFromString(urlString: string): string | undefined {
  if(!urlString) return undefined;

  const regex = /url\("([^"]+)"\)/;
  const match = urlString.match(regex);
  if (match) {
    const urlParts = match[1].split('/');
    return `../../public/content/isp/images/login/${urlParts[urlParts.length - 1]}`;
  } else {
    return undefined;
  }
}

const isAvatarDefaultValue = { internal: false, external: false };
const urlDefaultValue = { internal: undefined, external: undefined };

export function useAvatarIsp() {
  const [isAvatar, setIsAvatar] = useState<IAvatarState>(isAvatarDefaultValue);
  const [url, setUrl] = useState<IUrlState>(urlDefaultValue);
  const { user } = useUserContext();

  const imgExternal = extractUrlFromString(usePickStyle({ id: 'login-avatar-external', styleProperty: 'background-image'}));
  
  useEffect(() => {
    const fetchAvatar = async () => {
      if (user?.isp?.codigo) {
        try {
          const module = await import(
            `../../public/content/isp/images/login/avatar_${user.isp.codigo}.png`
          );

          setIsAvatar({ internal: true, external: false });
          setUrl({ internal: module.default, external: undefined });
        } catch (error) {
          setIsAvatar(isAvatarDefaultValue);
          setUrl(urlDefaultValue);
        }
      }
    };
    
    if (imgExternal) {
      setIsAvatar({ internal: false, external: true });
      setUrl({ internal: undefined, external: imgExternal as string });
    } else {
      fetchAvatar();
    }

  }, [user?.isp?.codigo, imgExternal]);

  return {
    isAvatar,
    url,
  };
}
