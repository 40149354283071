import i18n from 'i18next';

import {initReactI18next} from "react-i18next";
const regionalizacao = localStorage.getItem('idioma');
const initialLanguage = regionalizacao === 'BR' ? 'BR' : 'ES';


i18n
.use(initReactI18next)
.init({

    debug:true,
    lng: initialLanguage,
    resources:{
        BR:{
            translation:{
                message1: "Você deve selecionar",
                message2: "serviço(s) da categoria Standard e",
                message3: "serviço(s) da categoria Standard",
                message4: "serviço(s) da categoria Premium",
                restam: "Restam",
                resta: "Resta",
                envios: " envios de autenticação este mês.",
                envio: " envio de autenticação este mês.",
                codigoEnviado: "Um código de autenticação foi enviado via SMS para o telefone ",
                digiteCodigo: ". Por favor digite esse código no campo abaixo.",
                codigoEnviadoTelefone: "Um código de autenticação foi enviado via SMS para o telefone",
                novoCodigo: "Um novo código de autenticação foi enviado via SMS para o telefone ",
                limiteMensalAtingido: "Atingido limite mensal de autenticações para um cliente. Por favor, contate seu provedor.",
                limiteAtingido: "Atingido limite de autenticações para um telefone. Por favor, contate seu provedor",
                limiteAutenticacao: "Limite de Autenticação",
                autenticacaoTelefoneInvalida:"Telefone Inválido",
                telefoneInvalido: "O telefone celular cadastrado é inválido. Envio de código de autenticação via SMS impossibilitado. Favor contatar seu provedor.",
                reinicieProcedimento: "Reinicie o procedimento",
                situacaoAlterada: "Situação alterada por outro processo. Por favor, reinicie o procedimento.",
                codigoInvalido:"Codigo inválido",
                codigoVencido:"Codigo vencido",
                reenviarCodigo: "reenviar código",
                enviarNovoCodigo: "enviar novo código",
                semDescricao: "Sem descrição",
                noSeuPacote: "Já no seu pacote",
                selecionePacoteStandard: "Selecione seu(s) aplicativo(s) Standard",
                selecionePacotePremium: "Selecione seu(s) aplicativo(s) Premium",
                em:" em ",
                tipoAtutenticacao:"Tipo de autenticação incorreta.",
                celularValidacao:"O telefone deve ser válido.",
                modeloNaoConfigurado :"Modelo de envio não configurado.",
                erroEnvioDados:"Erro no envio dos dados.",
                pesquisar: "Pesquisar...",
                portalAssinante: " Bem-vindo ao Portal do Assinante",
                escolha: "Escolha seus aplicativos em",
                poucosCliques: "poucos cliques",
                digite: "Digite 3 ou mais caracteres para pesquisar",
                digiteTelefone:"Digite o número do telefone celular registrado",
                enviarCodigoVerificacao: "Enviar código de verificação por:",
                nenhumResultado : "Nenhum resultado",
                captcha:"pt",
                acessar: "Acessar",
                loginTelefone: "Login por telefone",
                loginUsuario: "Login por usuário",
                defaultError: "Desculpe, ocorreu um erro inesperado. Tente novamente mais tarde.",
                defaultSuccess: "Operação realizada com sucesso.",
                maximoQuantidadePacotes: "Você já atingiu a quantidade máxima de produtos selecionados para essa categoria",
                excedeuQuantidadePermitidaGeneric: "Limite de aplicativos excedido.",
                excedeuQuantidadePermitidaStandard: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Standard para salvar sua seleção.",
                excedeuQuantidadePermitidaPremium: "Limite de aplicativos excedido. Desmarque um ou mais aplicativos da categoria Premium para salvar sua seleção.",
                salvar: "Salvar", 
                serverTimeout: "Desculpe, não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.",
                senha: "Senha",
                solicitacaoLogin: "Faça login para continuar.",
                usuario: "Usuario",
                telefone: "",
                codigo: "Código",
                sms: "SMS",
                whatsapp: "WhatsApp",
                usuarioOffline: 'Por favor, verifique sua conexão com a internet antes de continuar.',
                provedor:"Provedor",
                reenviarNovo: "Reenviar novo Código",
                limiteDeAutenticacao: "Limite de Autenticação",
                telefoneInvalidolbl: "Telefone inválido",
                sair:"Sair",
                ola:"Olá",
                navegacaoRapida:"Navegação rápida",
                selecionePacoteAdvanced:"SELECIONE SEU(S) APLICATIVO(S) ADVANCED",
                selecionePacotePremiumAnuncio:"SELECIONE SEU(S) APLICATIVO(S) PREMIUM ANÚNCIO",
                selecionePacotePremiumSuper:"SELECIONE SEU(S) APLICATIVO(S) PREMIUM SUPER",
                selecionePacotePremiumSuperExtra:"SELECIONE SEU(S) APLICATIVO(S) PREMIUM SUPER EXTRA",
                abrir:"abrir",
                selecionar:"Selecionar",
                selcionePacoteStandard:"SELECIONE SEU(S) APLICATIVO(S) STANDARD",
                ativos :"Ativos",
                disponivel:"Disponível",
                selecioneNba : "Selecione a equipe NBA",
                jaIncluso: "Já incluso",
                cliqueEnviarCodigo: "Por favor clique no botão Enviar Código para receber o código de autenticação",
                digiteCodigoEnviado: "Digite o código enviado para seu dispositivo",
                seuCodigo: "Seu código foi enviado. Caso não o tenha receibo, aguarde",
                solicitarNovamente: "para reenviá-lo",
            },
        },
        ES:{
            translation:{
                message1: "Debes elegir",
                message2: "servicio(s) de categoría Estándar y",
                message3: "serviço(s) da categoria Standard",
                message4: "servicio(s) de categoría premium",
                restam: "Permanecer",
                resta: "Resta",
                envios: " envíos de autenticación este mes.",
                envio: " enviando autenticación este mes.",
                codigoEnviado: "Se envió un código de autenticación por SMS al teléfono.",
                digiteCodigo: ". Por favor ingrese este código en el campo a continuación.",
                codigoEnviadoTelefone: "Se envió un código de autenticación por SMS al teléfono.",
                novoCodigo: "Se envió un nuevo código de autenticación por SMS al teléfono",
                limiteMensalAtingido: "Se ha alcanzado el límite de autenticación mensual para un cliente. Por favor contacte a su proveedor.",
                limiteAtingido: "Se alcanzó el límite de autenticación para un teléfono. Por favor contacte a su proveedor",
                limiteAutenticacao: "Umbral de autenticación",
                autenticacaoTelefoneInvalida:"Teléfono no válido",
                telefoneInvalido: "El teléfono celular registrado no es válido. No es posible enviar el código de autenticación por SMS. Por favor contacte a su proveedor.",
                reinicieProcedimento: "Reiniciar el procedimiento",
                situacaoAlterada:"La situación cambió por otro proceso. Por favor reinicie el procedimiento.",
                Codigoinválido:"Codigo invalido",
                codigoVencido:"código caducado",
                reenviarCodigo: "reenviar codigo",
                enviarNovoCodigo: "enviar nuevo código",
                semDescricao: "No hay una descripción",
                noSeuPacote: "Ya en tu paquete",
                selecionePacoteStandard: "Seleccione su(s) aplicación(es) Estándar",
                selecionePacotePremium: "Seleccione su(s) aplicación(es) Premium",
                em:" en ",
                tipoAtutenticacao:"Tipo de autenticación incorrecto.",
                celularValidacao:"El teléfono debe ser válido.",
                modeloNaoConfigurado :"Modelo de envío no configurado.",
                erroEnvioDados:"Error en el envío de datos.",
                pesquisar: "Buscar...",
                portalAssinante: "Bienvenido al Portal del Suscriptor",
                escolha: "Elige tus aplicaciones en",
                poucosCliques: "unos cuantos clics",
                digite:"Ingrese 3 o más caracteres para buscar",
                digiteTelefone: "Ingrese el número de celular registrado",
                enviarCodigoVerificacao: "Enviar código de verificación por:",
                nenhumResultado : "No hay resultados",
                captcha:"es",
                acessar: "Acessar",
                loginTelefone: "Iniciar sesión por teléfono",
                loginUsuario: "Iniciar sesión por usuario",
                defaultError: "Lo siento, ocurrió un error inesperado. Vuelve a intentarlo más tarde.",
                defaultSuccess: "Operación realizada con éxito.",
                maximoQuantidadePacotes: "Ya has alcanzado el número máximo de productos seleccionados para esta categoría",
                excedeuQuantidadePermitidaGeneric: "Se superó el límite de solicitudes.",
                excedeuQuantidadePermitidaStandard: "Se superó el límite de solicitudes. Anule la selección de una o más aplicaciones de la categoría Estándar para guardar su selección.",
                excedeuQuantidadePermitidaPremium: "Se superó el límite de solicitudes. Anule la selección de una o más aplicaciones de la categoría Premium para guardar su selección.",
                salvar: "Guardar", 
                serverTimeout: "Lo sentimos, no pudimos establecer una conexión con el servidor. Vuelve a intentarlo más tarde.",
                senha: "Contraseña",
                solicitacaoLogin: "Inicia sesión para continuar.",
                usuario: "Usuario",
                telefone: "",
                codigo: "Código",
                sms: "SMS",
                whatsapp: "WhatsApp",
                usuarioOffline: 'Por favor verifique su conexión a Internet antes de continuar.',
                provedor:"Proveedor",
                reenviarNovo: "Reenviar nuevo código",
                limiteDeAutenticacao: "Límite de autenticación",
                telefoneInvalidolbl: "Teléfono no válido",
                sair:"Para salir",
                ola:"Hola",
                navegacaoRapida:"Navegación rápida",
                selecionePacoteAdvanced:"SELECCIONE SU(S) APLICACIÓN(ES) AVANZADA(S)",
                selecionePacotePremiumAnuncio:"SELECCIONE SU(S) APLICACIÓN(ES) AVANZADA(S)",
                selecionePacotePremiumSuper:"SELECCIONA TU(S) SUPER APP(S) PREMIUM",
                selecionePacotePremiumSuperExtra:"SELECCIONE SU(S) APLICACIÓN(ES) SUPER EXTRA PREMIUM",
                abrir:"abra",
                selecionar: "Seleccionar",
                selcionePacoteStandard:"SELECCIONE SU(S) APLICACIÓN(ES)  STANDARD",
                ativos :"Activos",
                disponivel:"Disponibles",
                selecioneNba : "Seleccione su equipo NBA",
                jaIncluso: "Ya incluido",
                cliqueEnviarCodigo: "Haga clic en el botón Enviar código para recibir el código de autenticación.",
                digiteCodigoEnviado: "Digite el código enviado a su celular",
                seuCodigo: " Su código fue enviado. En caso que no lo haya recibido, espere",
                solicitarNovamente: "para solicitarlo nuevamente"
            },

        },
    }
})