import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserContextProvider from './authentication/userContextProvider';
import NotificationContextProvider from './messages/context';
import AxiosInterceptor from './api/interceptor';
import "./translate/i18n";

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <NotificationContextProvider>
        <AxiosInterceptor>
          <App />
        </AxiosInterceptor>
      </NotificationContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
