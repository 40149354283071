function rgbStringToComponents(rgb: string): { r: number, g: number, b: number } {
    const result = rgb.match(/\d+/g);
    if (result && result.length === 3) {
        const [r, g, b] = result.map(Number);
        return { r, g, b };
    } else {
        throw new Error("Invalid RGB format");
    }
}

function rgbToHex(r: number, g: number, b: number): string {
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`.toUpperCase();
}

export function invertRgbColor(rgb: string | undefined): string {
    if(!rgb) return '#FFFFFF'

    const { r, g, b } = rgbStringToComponents(rgb);

    const invertedR = 255 - r;
    const invertedG = 255 - g;
    const invertedB = 255 - b;

    return rgbToHex(invertedR, invertedG, invertedB);
}