import axios from 'axios';
import config from '../config/config';

const tokenJwt = localStorage.getItem("tokenJwt");

const api = axios.create({
    baseURL: config.apiUrlBase,
    headers: {
        Authorization: tokenJwt ? `Bearer ${tokenJwt}` : undefined
    }
});

export default api;
