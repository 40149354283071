import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import IModalOptionals from "../interfaces/IModalOptionals";
import IProduto from "../interfaces/produto";
import FilterListIcon from "@material-ui/icons/FilterList";
import React from "react";
import IModalTelefoneOptionals from "../interfaces/IModalTelefoneOptionals";
import { t } from "i18next";


export default function ModalConfirm({
  headerMessage = "",
  bodyStandard = [],
  bodyPremium = [],
  bodyNivel004 = [],
  bodyNivel005 = [],
  bodyNivel006 = [],
  bodyNivel007 = [],
  open = false,
  handleClose = () => {},
  handleConfirm = (operacao: number) => {},
}) {
  const createBodyList = (title: string, list: IProduto[]) => {   
    if (list.length > 0) {      
      return (
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListSubheader
            component="div"
            className="position-relative padding-none"
          >
            {title}
          </ListSubheader>

          {list.map((item) => {
               return (
              <ListItem className="padding-left-none">
                <ListItemAvatar>
                  <Avatar
                    src={`./content/produto/images/produto_${item.codigo}.png`}
                    alt={item.descricao}
                    className="avatar-small"
                  />
                </ListItemAvatar>

                <ListItemText primary={item.descricao} />
              </ListItem>
            );         
          })}
        </List>
      );
    }

    return null;
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar"}</DialogTitle>
        <DialogContent>
          {headerMessage}
          <br />
          <br />

          <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
            {createBodyList("Produtos Standard", bodyStandard)}
            {createBodyList("Produtos Advanced", bodyNivel004)}
            {createBodyList("Produtos Premium", bodyPremium)}
            {createBodyList("Produtos Premium Anúncios", bodyNivel005)}
            {createBodyList("Produtos Super Premium", bodyNivel006)}
            {createBodyList("Produtos Super Premium Extra", bodyNivel007)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCELAR</Button>
          <Button
            onClick={() => handleConfirm(0)}
            className="button-confirm"
            autoFocus
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ModalInfo({
  headerMessage = "",
  bodyMessage = "",
  url = "",
  open = false,
  handleClose = () => {},
}) {
  const isBodyMessageUrl = (body: string) => {
    try {
      var urlCheck = new URL(body);
      if (urlCheck.protocol && urlCheck.hostname) return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  if (isBodyMessageUrl(bodyMessage)) {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogContent>
            <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
              <iframe
                title={headerMessage}
                style={{ border: "none", width: "100%", height: "100vh" }}
                src={bodyMessage}
              ></iframe>
              <Typography variant="subtitle1">
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>FECHAR</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{headerMessage}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
              {bodyMessage}
              <br />
              <a href={url} target="_blank" rel="noreferrer">
                  {url}
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>FECHAR</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export const ModalOptionals: React.FunctionComponent<IModalOptionals> = ({
  open = false,
  optionals,
  handleCancel,
  handleSave,
  chosen,
}) => {
  const [filteredOptionals, setFilteredOptionals] = useState<Array<any>>([]);
  const [value, setValue] = useState<string>("");
  const [initialValue, setInitialValue] = useState<string>("");

  useEffect(() => {
    setFilteredOptionals(optionals.data);
  }, [setFilteredOptionals, optionals.data]);

  useEffect(() => {
    if (chosen.length > 0) {
      const found = chosen.find((item) => item.type === optionals.type);
      setValue(found ? found.id : "");
      setInitialValue(found ? found.id : "");
    }
  }, [chosen, optionals.type]);

  const handleClose = () => {
    setValue(initialValue);
    handleCancel();
  };

  const getCustomBackgroundClass = (type: string) => {
    switch (type) {
      case "NbaTeamChoice":
        return "optionals-modal-content--nbatc";
      default:
        return "";
    }
  };

  const getCustomHeader = (type: string) => {
    switch (type) {
      case "NbaTeamChoice":
        return t("selecioneNba");
      default:
        return "";
    }
  };

  const handleFilterOptionals = (filter: string) => {
    const filterFunction = (item: any) => {
      return item.nome.toLowerCase().includes(filter.toLowerCase());
    };
    if (filter.length > 0) {
      const newFiltered = optionals.data.filter(filterFunction);
      setFilteredOptionals(newFiltered);
    } else {
      setFilteredOptionals(optionals.data);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        className="optionals-modal"
      >
        <div
          className={`optionals-modal-content ${getCustomBackgroundClass(
            optionals.type
          )}`}
        >
          <DialogTitle className="optionals-text">
            {getCustomHeader(optionals.type)}
          </DialogTitle>
          <div className="optionals-filter-container">
            <FilterListIcon fontSize="large" className="optionals-text" />
            <TextField
              onChange={(event) => handleFilterOptionals(event.target.value)}
              placeholder="Filtre pelo nome desejado"
              variant="outlined"
              className="optionals-filter"
              fullWidth
            />
          </div>
          <DialogContent className="radio-form">
            <FormControl component="fieldset">
              <RadioGroup
                className="radio-form-content"
                value={value}
                onChange={(event) => setValue(event.target.value)}
              >
                {filteredOptionals.length > 0 ? (
                  filteredOptionals.map((op, index) => {
                    return (
                      <Fragment key={index}>
                        <div className="radio-container">
                          <FormControlLabel
                            value={op.id}
                            control={<Radio color="primary" />}
                            label={
                              <div className="radio-label">
                                <Avatar
                                  src={`./content/produto/images/${optionals.type}-opcionais/${optionals.type}_${op.id}.jpg`}
                                  className="optional-logo"
                                  variant="square"
                                  imgProps={{
                                    className: "optional-logo-img",
                                  }}
                                  alt={op.nome}
                                />
                                {op.nome}
                              </div>
                            }
                          />
                        </div>
                        <Divider className="radio-divider" />
                      </Fragment>
                    );
                  })
                ) : (
                  <div>{t("nenhum")}.</div>
                )}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions className="optionals-button-container">
            <Button
              onClick={() => {
                handleSave(
                  optionals.type,
                  value,
                  filteredOptionals.find((item) => item.id === value).nome
                );
              }}
              size="large"
              className="button-primary optionals-text"
            >
              {t("salvar")}
            </Button>
            <Button
              className="optionals-text"
              onClick={() => {
                handleClose();
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export const ModalClienteOptionals: React.FunctionComponent<
  IModalOptionals
> = ({
  open = false,
  optionals,
  handleCancel,
  handleSave,
  chosen,
  textTrueButton,
}) => {
  const [filteredOptionals, setFilteredOptionals] = useState<Array<any>>(
    optionals.data
  );

  useEffect(() => {
    setFilteredOptionals(optionals.data);
  }, [setFilteredOptionals, optionals.data]);

  const handleClose = () => {
    handleCancel();
  };

  const getCustomBackgroundClass = (type: string) => {
    switch (type) {
      case "NbaTeamChoice":
        return "optionals-modal-content--nbatc";
      default:
        return "";
    }
  };

  const getCustomHeader = (type: string) => {
    switch (type) {
      case "NbaTeamChoice":
        return t("selecioneNba");
      default:
        return "";
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        className="optionals-modal"
      >
        <div
          className={`optionals-modal-content ${getCustomBackgroundClass(
            optionals.type
          )}`}
        >
          <DialogTitle className="optionals-text">
            {getCustomHeader(optionals.type)}
          </DialogTitle>
          <div className="radio-form-canvas">
            <h1 className="radio-form-title">Qual destes é você?</h1>
            <DialogContent className="radio-form">
              {filteredOptionals.length > 0 ? (
                <FormControl>
                    {filteredOptionals.map((op, index) => (
                      <Button key={index} variant="text" onClick={() => {
                        handleSave(
                          optionals.type,
                          op.idCliente.toString(),
                          op.nome
                        );
                      }}>{`${op.nome} - ${op.login}`}</Button>
                    ))}
                </FormControl>
              ) : (
                <div>{t("nenhum")}</div>
              )}
            </DialogContent>
          </div>

          <DialogActions className="optionals-button-container">
            <Button className="button-primary optionals-text" onClick={handleClose}>
              Cancelar
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
