import { TipoAutenticacaoEnum } from "./TipoAutenticacaoEnum";


export default interface IAuthenticationData {
  tokenRecaptcha: string;
  tipoAutenticacao : TipoAutenticacaoEnum
}

export class AuthenticationData implements IAuthenticationData {
  public tokenRecaptcha: string;
  public tipoAutenticacao: TipoAutenticacaoEnum;

  constructor(tokenRecaptcha: string, tipoAutenticacao: TipoAutenticacaoEnum) {
    this.tokenRecaptcha = tokenRecaptcha;
    this.tipoAutenticacao = tipoAutenticacao;
  }
}