import api from "../api";

interface EnviaTokenBody {
  idCliente: number;
  modeloEnvio: string;
}

interface ValidaTokenBody {
  idCliente: number;
  codigo: string;
}

const EnviaToken = async function (idCliente: number, modeloEnvio: string) {
  const body: EnviaTokenBody = {
    idCliente: idCliente,
    modeloEnvio: modeloEnvio,
  };

  return await api.post("/arpcodigo/enviar", body);
};

const ValidaToken = async function (idCliente: number, codigo: string) {
  const body: ValidaTokenBody = {
    idCliente: idCliente,
    codigo: codigo,
  };

  return await api.post("/arpcodigo/resgatar", body);
};

const TokenStatus = async function (idCliente: number) {
  return await api.get(`/arpcodigo/consultar/${idCliente}`);
};

const phoneLogin = {
  EnviaToken,
  ValidaToken,
  TokenStatus,
};

export default phoneLogin;
