import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Typography,
  TextField,
  Link
} from '@material-ui/core';
import './style.css';
import { useEffect, useState } from 'react';
import aut2FatService from '../../services/aut2FatService';
import IAut2FatCod from '../../interfaces/aut2FatCod';



export default function ModalAut2fat({ attentionMessage = "", bodyText = "", bodyStandard = [], bodyPremium = [], responseReenvio = '', open = false, handleClose = () => { }, handleAut2 = (codigo: string) => { return codigo }, verifyAut2Fat = (operacao: string) => { return operacao } }) {
  const [codigoAutenticacao, setCodigoAutenticacao] = useState<string>('');
  const [metodoEnvio, setMetodoEnvio] = useState<string>('');

  useEffect(() => {
    if (responseReenvio === 'reenviar código') {
      setMetodoEnvio('Reenviar')
    }
    else if (responseReenvio === 'enviar novo código') {
      setMetodoEnvio('Renovar')
    }
  }, [responseReenvio])

  const handleEnviaCodigo = async (modelo: string) => {
    const request: IAut2FatCod = {
      PaAdfOperacao: metodoEnvio,
      modeloEnvio: modelo
    }
    const response = await aut2FatService.getCodigoAut2Fat(request);
    // console.log(response);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{"Autenticação"}</DialogTitle>
        <DialogContent>
          <Typography variant="caption" className='attention-message'>{attentionMessage}</Typography>

          <br />
          <br />

          <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
            <b> {bodyText} </b>
            <br />
            <br />
            <TextField id="outlined-basic" label="Código" variant="outlined" fullWidth onChange={((event) => { setCodigoAutenticacao(event.target.value) })} />

          </DialogContentText>
          <DialogActions>
            <span>{responseReenvio} por <Link className='linkEnvia' onClick={() => handleEnviaCodigo("Sms")}>Sms</Link> ou <Link className='linkEnvia' onClick={() => handleEnviaCodigo("Whatsapp")}>Whatsapp</Link></span>
          </DialogActions>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="outlined" onClick={() => { handleAut2(codigoAutenticacao) }} className="button-aut2-confirm" size="medium" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ModalMessage({ headerMessage = "", bodyMessage = "", open = false, handleClose = () => { } }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{headerMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
            <Typography variant="body1">{bodyMessage}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}