export default interface IInformacoesQuantidadeProdutos {
    disponivel: number;
    selecionado: number;
    totalPermitido: number;
}


const InitialValues:IInformacoesQuantidadeProdutos = {
    disponivel: 0,
    selecionado: 0,
    totalPermitido: 0
}

export {
    InitialValues
};