import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import IUserContext from "../interfaces/userContext";
import api from "../api";
import sAuthentication from "../services/authenticationService";
import IUser from "../interfaces/user";
import history from "../routes/history";
import IAuthenticationData from "../interfaces/AuthenticationData";
import { TipoAutenticacaoEnum } from "../interfaces/TipoAutenticacaoEnum";
import { RegionalizacaoEnum } from "../interfaces/RegionalizacaoEnum";

const UserContext = createContext<IUserContext | undefined>(undefined);

// Gera data de expiração do token para 7 dias
const expirationTimeGenerate = new Date().getTime() + 1000 * 60 * 60 * 24 * 7;

const UserContextProvider: React.FunctionComponent<{}> = ({ children }) => {
  const [user, setUser] = useState<IUser>();

  // Recupera dados do usuário do localStorage
  useEffect(() => {
    const tokenJwt = localStorage.getItem("tokenJwt") as string;
    const nome = localStorage.getItem("nome") as string;
    const codigo = localStorage.getItem("codigo") as string;
    const codigoIsp = localStorage.getItem("codigoIsp") as string;
    const codigoUrl = localStorage.getItem("codigoUrl") as string;
    const nomeFantasia = localStorage.getItem("nomeFantasia") as string;
    const tipoLogin = localStorage.getItem(
      "tipoAutenticacaoPortalAssinante"
    ) as string;
    const regionalizacao = localStorage.getItem("regionalizacao") as string;
    const expirationTime = parseInt(
      localStorage.getItem("expirationTime") as string
    );

    const index = Object.values(TipoAutenticacaoEnum).indexOf(
      tipoLogin as unknown as TipoAutenticacaoEnum
    );
    const tipoLoginEnum = Object.keys(TipoAutenticacaoEnum)[index];

    const indexReg = Object.values(RegionalizacaoEnum).indexOf(
      regionalizacao as unknown as RegionalizacaoEnum
    );
    const regionalizacaoEnum = Object.keys(TipoAutenticacaoEnum)[indexReg];

    if (
      tokenJwt &&
      nome &&
      codigoIsp &&
      codigo &&
      codigoUrl &&
      nomeFantasia &&
      codigoIsp &&
      tipoLoginEnum &&
      regionalizacaoEnum
    ) {
      if (new Date().getTime() > expirationTime) {
        handleLogout();
      }

      setUser({
        nome,
        tokenJwt,
        isp: {
          codigo: codigo,
          codigoUrl: codigoUrl,
          nomeFantasia: nomeFantasia,
          codigoIsp: codigoIsp,
          tipoAutenticacaoPortalAssinante: tipoLoginEnum,
          regionalizacao: regionalizacaoEnum,
        },
        isAuthenticated: true,
      });
      api.defaults.headers.Authorization = `Bearer ${tokenJwt}`;
    }
  }, []);

  const handleLogin = useCallback(
    async (
      username: string,
      password: string,
      codigoIsp: string,
      data: IAuthenticationData,
      codigo: string,
      acessoNBA = false
    ) => {
      sAuthentication
        .postLogin(username, password, codigoIsp, data, codigo, acessoNBA)
        .then((response: any) => {
          setUser({
            nome: response.data.nome,
            isp: {
              codigo: response.data.isp.codigo,
              codigoUrl: response.data.isp.codigoUrl,
              nomeFantasia: response.data.isp.nomeFantasia,
              codigoIsp: acessoNBA ? codigoIsp : response.data.isp.codigoIsp,
              tipoAutenticacaoPortalAssinante: data.tipoAutenticacao.toString(),
              regionalizacao: response.data.isp.regionalizacao,
            },
            tokenJwt: response.headers["x-access-token"],
            isAuthenticated: true,
            expirationTime: expirationTimeGenerate,
          });
          localStorage.setItem("nome", response.data.nome);
          localStorage.setItem("codigo", response.data.isp.codigo);
          localStorage.setItem("codigoUrl", response.data.isp.codigoUrl);
          localStorage.setItem(
            "codigoIsp",
            acessoNBA ? codigoIsp : response.data.isp.codigoIsp
          );
          localStorage.setItem("nomeFantasia", response.data.isp.nomeFantasia);
          localStorage.setItem("isNBAAcess", acessoNBA ? "1" : "0");
          localStorage.setItem("tokenJwt", response.headers["x-access-token"]);
          localStorage.setItem(
            "tipoAutenticacaoPortalAssinante",
            response.data.isp.tipoAutenticacaoPortalAssinante
          );
          localStorage.setItem(
            "expirationTime",
            expirationTimeGenerate.toString()
          );
          localStorage.setItem(
            "regionalizacao",
            response.data.isp.regionalizacao
          );
          if (acessoNBA) {
            history.push(`/${codigoIsp}/servicos`);
          } else {
            history.push(`/${response.data.isp.codigoUrl}/servicos`);
          }
        })
        .catch((err) => {});
    },
    []
  );

  const alteraRegionalizacao = (regionalizacao: string) => {
    setUser(prevState => ({
      ...prevState,
      isp: {
        ...prevState?.isp,
        regionalizacao,
        codigo: prevState?.isp?.codigo || "",
        codigoUrl: prevState?.isp?.codigoUrl || "",
        nomeFantasia: prevState?.isp?.nomeFantasia || "",
        codigoIsp: prevState?.isp?.codigoIsp || "",
        tipoAutenticacaoPortalAssinante: prevState?.isp?.tipoAutenticacaoPortalAssinante || "",
      }
    }));  
  }

  const handleLogout = useCallback(() => {
    const path = localStorage.getItem("codigoUrl");
    localStorage.clear();
    setUser({ ...user, isAuthenticated: false, tokenJwt: undefined, nome: "" });
    history.push(`/${path}`);
  }, [user?.isp?.codigoUrl]);

  return (
    <UserContext.Provider
      value={{ user, login: handleLogin, logout: handleLogout, setUser, alteraRegionalizacao }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
}

export default UserContextProvider;
