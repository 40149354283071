import { CSSProperties, useEffect, useState, useCallback } from "react";
import { useUserContext } from "../authentication/userContextProvider";

interface IProps {
    id: string;
    styleProperty?: string;
    fileAwait?: string;
}

interface IUpdateStyleValue {
    id: string;
    styleProperty: string;
    setStyleValue: React.Dispatch<React.SetStateAction<string | CSSProperties | undefined>>;
    styleValue: string | CSSProperties | undefined;
}

const toCamelCase = (str: string): string => str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

const getStyleObject = (computedStyle: CSSStyleDeclaration): CSSProperties => {
    const styleObject: { [key: string]: string } = {};
    
    for (let i = 0; i < computedStyle.length; i++) {
        const propertyName = computedStyle[i];
        const camelCasePropertyName = toCamelCase(propertyName);
        styleObject[camelCasePropertyName] = computedStyle.getPropertyValue(propertyName) || "";
    }
    
    return styleObject;
};

const updateStyleValue = ({id, styleProperty, setStyleValue, styleValue} : IUpdateStyleValue): void => {
    const element = document.getElementById(id);
    if (element) {
        const computedStyle = window.getComputedStyle(element);
        if (styleProperty === '*') {
            const styleObject = getStyleObject(computedStyle);
            if(styleValue !== styleObject) setStyleValue(styleObject);
        } else {
            const styleObject = computedStyle.getPropertyValue(styleProperty);
            if(styleValue !== styleObject) setStyleValue(styleObject);
        }
    }
};

const checkStylesheetLoaded = (styleName: string, callback: () => void) => {
    const linkElement = Array.from(document.getElementsByTagName('link')).find(
        link => link.href.includes(styleName)
    );

    if (linkElement) {
        if (linkElement.sheet) {
            callback();
        } else {
            linkElement.addEventListener('load', callback);
            return () => {
                linkElement.removeEventListener('load', callback);
            };
        }
    } else {
        const retryInterval = setInterval(() => {
            const retryLinkElement = Array.from(document.getElementsByTagName('link')).find(
                link => link.href.includes(styleName)
            );
            if (retryLinkElement && retryLinkElement.sheet) {
                clearInterval(retryInterval);
                callback();
            }
        }, 100);
    }
};

export function usePickStyle<T = CSSProperties>({ id, styleProperty = '*', fileAwait }: IProps): T {
    const [styleValue, setStyleValue] = useState<CSSProperties | string>();
    const { user } = useUserContext();
    const styleName = fileAwait || `isp_${user?.isp?.codigo}.min.css`;

    const updateStyleValueCallback = useCallback(() => {
        updateStyleValue({ id, styleProperty, setStyleValue, styleValue });
    }, [id, styleProperty]);

    useEffect(() => {
        if (document.readyState === 'complete') {
            checkStylesheetLoaded(styleName, updateStyleValueCallback);
        } else {
            window.addEventListener('load', () => checkStylesheetLoaded(styleName, updateStyleValueCallback));
            return () => {
                window.removeEventListener('load', () => checkStylesheetLoaded(styleName, updateStyleValueCallback));
            };
        }
    }, [styleName, updateStyleValueCallback]);

    return styleValue as T;
}