import api from "../api";

const listClientesTelefone = function (
  ispCodigo: string,
  telefone: string,
  recaptchaToken: string
) {
  return api.post("/arpcodigo/cliente/telefone", {
    ispCodigo: ispCodigo,
    telefone: telefone,
    recaptchaToken: recaptchaToken,
  });
};

const clienteService = {
  listClientesTelefone,
};

export default clienteService;
