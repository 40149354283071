export enum RegionalizacaoEnum {
  Brasil = 1,
  Colombia = 2,
  Peru = 3,
}

export enum RegionalizacaoCodigoEnum {
  Brasil = "BR",
  Colombia = "CO",
  Peru = "PE",
}
