import React, { useEffect, useState } from 'react';
import { Router, Switch } from 'react-router-dom';
import { useUserContext } from './authentication/userContextProvider';
import LoginPage from './pages/login';
import PartnersPage from './pages/pacote';
import PartnersPageNBA from './pages/pacoteNBA';
import history from './routes/history';
import Route from './routes/route';


const App: React.FunctionComponent<{}> = function(props) {
  const { user } = useUserContext();
  const [stylePath, setStylePath] = useState<string>();
  // Carrega path do css correspondente ao isp
  useEffect(() => {
    if(window.location.pathname === "/nba-acesso"){
      setStylePath(`./content/isp/styles/isp_nba.min.css`);
    } else if (user && user.isp?.codigo) {
      setStylePath(`./content/isp/styles/isp_${user.isp.codigo}.min.css`);
    }
  }, [user]);
  return (
    <>
      {stylePath && <link rel="stylesheet" type="text/css" href={stylePath} />}
      <Router history={history}>
        <Switch>
            <Route exact path="/:isp_code" component={LoginPage} isNBA/>
            
            {/* Necessita autenticação */}
            <Route exact path="/nba-acesso/servicos" component={PartnersPageNBA} isNBA isPrivate />
            <Route exact path="/:isp_code/servicos" component={PartnersPage} isPrivate />
        </Switch>
      </Router>
    </>
  )
}

export default App;
