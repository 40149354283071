import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import IValidaTelefoneAuthModal from "../../interfaces/IValidaTelefoneAuthModal";
import "./styles.css";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import sPhoneLoginService from "../../services/phoneLoginService";
import { ArpEnvioModeloEnvioEnum } from "../../interfaces/ArpEnvioModeloEnvioEnum";
import { useEffect, useState } from "react";
import { useUserContext } from "../../authentication/userContextProvider";
import { AuthenticationData } from "../../interfaces/AuthenticationData";
import { TipoAutenticacaoEnum } from "../../interfaces/TipoAutenticacaoEnum";
import IStatusEnvioTelefone from "../../interfaces/IStatusEnvioTelefone";
import { useMessage } from "../../messages/context";
import { useCountDown } from "../../hooks/useCountDown";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel";
import { useTranslation } from "react-i18next";

export const ValidaTelefoneAuthModal = ({
  open,
  handleClose,
  arpEnvio,
  idCliente,
  captcha,
}: IValidaTelefoneAuthModal) => {
  const [codigoValida, setCodigoValida] = useState<string>("");
  const [statusEnvio, setStatusEnvio] = useState<IStatusEnvioTelefone>({
    status: "",
  });
  const { count, isCounting, maxValue, startCountdown, stopCountdown, countFormatMinSec } = useCountDown();
  const [inputErro, setInputErro] = useState<string>("");
  const { user, login } = useUserContext();
  const { showAlertApi } = useMessage();

  const { t } = useTranslation();
  
  useEffect(() => {
    if (statusEnvio.temCodigoValido === false) {
      handleEnviaCodigo();
    }
  }, [statusEnvio]);

  useEffect(() => {
    const checkTokenStatus = async () => {
      if (open && idCliente) {
        const statusResp = await sPhoneLoginService.TokenStatus(idCliente);
        if (statusResp.status === 200) {
          setStatusEnvio({
            status: statusResp.data.status,
            enviosRestantes: statusResp.data.enviosRestantes,
            temCodigoValido: statusResp.data.temCodigoValido,
            tempoRestanteNovoEnvio: statusResp.data.tempoRestanteNovoEnvio,
          });
          if (statusResp.data.tempoRestanteNovoEnvio) {
            startCountdown(statusResp.data.tempoRestanteNovoEnvio);
          }
        }
      }
    };
    checkTokenStatus();
  }, [open, idCliente]);

  const keyToEnum = (value: number) => {
    const index = Object.values(ArpEnvioModeloEnvioEnum).indexOf(
      value as unknown as ArpEnvioModeloEnvioEnum
    );
    return Object.keys(ArpEnvioModeloEnvioEnum)[index];
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodigoValida(event.target.value.replace(/\D/g, "")); //habilita o input para receber apenas valores numericos
  };

  const handleEnviaCodigo = async () => {
    stopCountdown();
    if (arpEnvio !== undefined) {
      setInputErro("");
      const arpEnum = keyToEnum(arpEnvio);
      const resp = await sPhoneLoginService.EnviaToken(idCliente, arpEnum);
      if (resp.status === 200 && resp.data.status === "Liberado") {
        setStatusEnvio({
          status: resp.data.status,
          tempoRestanteNovoEnvio: resp.data.tempoRestanteNovoEnvio,
          enviosRestantes: resp.data.enviosRestantes,
          });
        startCountdown(resp.data.tempoRestanteNovoEnvio);
        setCodigoValida("");
      } else {
        showAlertApi(resp.data.mensagem, "warning", 5000);
      }
    }
  };

  const handleResgataCodigo = async () => {
    //Testa se o codigoValida possui 8 digitos
    if (/^\d{8}$/.test(codigoValida)) {
      setInputErro("");
      const resp = await sPhoneLoginService.ValidaToken(
        idCliente,
        codigoValida
      );
      if (resp.status === 200) {
        const authData = new AuthenticationData(
          "",
          TipoAutenticacaoEnum.Telefone
        );
        login(
          idCliente,
          idCliente,
          user?.isp?.codigoUrl,
          authData,
          user?.isp?.codigo
        );
      }
    } else {
      setCodigoValida("");
      setInputErro("O código deve conter 8 digitos numéricos");
    }
  };

  const closeModal = () => {
    setStatusEnvio({ status: "" });
    setInputErro("");
    stopCountdown();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box style={{ padding: 5}}>
        <DialogTitle>
          <div>
            {!isCounting && !statusEnvio.temCodigoValido ? (
              <span>
                {t("cliqueEnviarCodigo")}
              </span>
            ) : (
              <span>{t("digiteCodigoEnviado")}</span>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            {statusEnvio?.enviosRestantes !== undefined ? (
              <span className="remainingSend">
                *Envios restantes: {statusEnvio.enviosRestantes}
              </span>
            ) : (
              <></>
            )}
            <Grid item container>
              <TextField
                placeholder="Código"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleInputChange}
                // className="codigoInput"
                value={codigoValida}
                error={!!inputErro}
                helperText={inputErro}
                inputProps={{ maxLength: 8}}
                fullWidth
              />
            </Grid>
            {(statusEnvio && count !== 0) && (
            <Grid item container style={{ marginBottom: 30 }} spacing={2}>
              <Grid item style={{ margin: 10 }}>
                <Typography variant="caption" style={{ fontSize: '15px' }} >
                  {t("seuCodigo")} {countFormatMinSec} {t("solicitarNovamente")}:
                </Typography>
              </Grid>
              <Grid item container alignItems="center" justify="center">
                <CircularProgressWithLabel value={count} textValue={countFormatMinSec} maxValue={maxValue} textSize={13} sizeProgress={50} />
              </Grid>
            </Grid>
            )}
            <Grid item container spacing={1}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  onClick={handleEnviaCodigo}
                  disabled={isCounting}
                  className="button-primary"
                  fullWidth
                  style={{
                    opacity: isCounting ? 0.6 : 1,
                  }}
                  >
                  {t("reenviarCodigo")}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                  <Button
                    variant="outlined"
                    onClick={handleResgataCodigo}
                    className="button-primary"
                    fullWidth
                    >
                    Continuar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: 10, paddingRight: 15 }}>
          <Button variant="text" onClick={closeModal} size="large" className="button">
            Cancelar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
