// WithAxios.js

import React, { useMemo } from 'react'
import api from './index';
import axios from 'axios';
import { useUserContext } from '../authentication/userContextProvider';
import { useMessage } from '../messages/context';
import history from '../routes/history';
import { httpStatusCode as HttpStatusCode } from '../config/enum';
import Message from '../config/message';
import useOnlineStatus from '../hooks/useOnlineStatus';
 
// Possibilita utilizar os React Hooks no tratamento de exceções.
const WithAxios: React.FunctionComponent<{}> = function(props) {
    const { setUser=()=>{}, user } = useUserContext();
    const { showLoading, hideLoading, showAlertApi } = useMessage();
    const isOnline = useOnlineStatus();

    useMemo(() => {
        api.interceptors.request.use(function (config) {
            if (!isOnline) {
                return Promise.reject(new axios.Cancel('isOffline'));
            }

            // ANTES DE QUALQUER REQUEST MOSTRA O LOADING
            showLoading();
            return config;
        }, function (error) {
            return Promise.reject(error);
        })

        api.interceptors.response.use(function (response) {
            hideLoading();
            // Atualiza Token JWT
            const tokenJwt = response.headers["x-access-token"];
            const csrfTkn = response.headers["x-csrf-token"];
            if (tokenJwt) {
                api.defaults.headers.Authorization = `Bearer ${tokenJwt}`;
            }

            if (csrfTkn) {
                api.defaults.headers.get['X-Csrf-Token'] = csrfTkn;
                api.defaults.headers.post['X-Csrf-Token'] = csrfTkn;
                api.defaults.headers.put['X-Csrf-Token'] = csrfTkn;
                api.defaults.headers.delete['X-Csrf-Token'] = csrfTkn;
                api.defaults.headers.patch['X-Csrf-Token'] = csrfTkn;
            }

            if (response.data.message) {
                showAlertApi(response.data.message, "success", 5000);
            }

            return Promise.resolve(response);
        }, function (error) {
            hideLoading();
            if (error.message === "isOffline") {
                return Promise.reject(error);
            }

            let message = Message.defaultError;
            let status:"error"|"info"|"warning"|"success" = "error";
            let timeout:number|undefined = 5000;

            if (error.code === "ECONNABORTED") {
                message = Message.serverTimeout;
            }

            if (error.response) {
                switch (error.response.status) {
                    case HttpStatusCode.Unauthorized:
                    case HttpStatusCode.Forbidden:
                        api.defaults.headers.Authorization = undefined;
                        setUser((prevState) => {
                            return {
                                ...prevState,
                                isAuthenticated: false
                            }
                        });

                        if (user && user.isp?.codigoUrl) {
                            history.push(`/${user.isp.codigoUrl}`);
                        }
                        break;
                    
                    case HttpStatusCode.BusinessException:
                        status = "warning";
                        break;
                }

                // Recupera mensagem enviada pela API
                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message;
                }

                showAlertApi(message, status, timeout);
                return Promise.reject(error);
            }
            
            showAlertApi(message, status, timeout);
            return Promise.reject(error);
        });

    }, [hideLoading, isOnline, setUser, showAlertApi, showLoading, user]);

    return (
        <>
            { props.children }
        </>
    );
}

export default WithAxios
