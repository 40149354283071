import api from '../api';
import IUpdateProduto from '../interfaces/updateProduto';

const listaProdutos = async function () {
  return await api.get('/produtos');
};
const listaProdutosNBA = async function () {
  return await api.get('/produtos/nbaAcesso');
};
const selecionaProdutos = async function (
  codigos: IUpdateProduto = {
    codigosAtivosStandard: [],
    codigosInativosStandard: [],
    codigosAtivosPremium: [],
    codigosInativosPremium: [],
    codigosAtivosNivel004: [],
    codigosInativosNivel004: [],
    codigosAtivosNivel005: [],
    codigosInativosNivel005: [],
    codigosAtivosNivel006: [],
    codigosInativosNivel006: [],
    codigosAtivosNivel007: [],
    codigosInativosNivel007: [],
  },
) {
  return await api.put('/produtos', codigos);
};

const svaService = {
  listaProdutos,
  selecionaProdutos,
  listaProdutosNBA
};

export default svaService;
